<template>
  <v-layout
    v-if="
      student.updateGeneral === false &&
        student.updateGuardian === false &&
        student.deleteAlert === false
    "
    row
    wrap
  >
    <v-flex xs4>
      <v-card class="primary--border" outlined>
        <v-card outlined>
          <v-list-item>
            <v-list-item
              v-on:click="
                $auth.can('student-update') ? SET_GENERAL_UPDATE(true) : ''
              "
            >
              <v-list-item-action>
                <v-icon color="#999">person</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="text-xs-right">
                  <strong>{{ student.data.name }}</strong>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item>
        </v-card>
        <v-card outlined>
          <v-list-item v-if="true">
            <v-list-item-action>
              <v-icon color="#999">cake</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text-xs-right">
                {{ student.data.personal ? student.data.personal.dob : "-" }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card outlined>
          <v-list-item v-if="true">
            <v-list-item-action>
              <v-icon color="#999">fa-at</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text-xs-right">
                {{ student.data.email || "-" }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card outlined>
          <v-list-item v-if="true">
            <v-list-item-action>
              <v-icon color="#999"
                >{{ !student.data.gender ? "fa-male" : "fa-female" }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text-xs-right">
                {{ student.data.personal ? student.data.personal.gender : "-" }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card outlined>
          <v-list-item
            v-on:click="
              $auth.can('student-update') ? SET_GUARDIAN_UPDATE(true) : ''
            "
          >
            <v-list-item-action>
              <v-icon color="#999">supervised_user_circle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <template v-if="student.data.guardian">
                <v-list-item-title class="text-xs-right">
                  {{ student.data.guardian.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-xs-right">
                  {{ student.data.guardian.relation }} /
                  {{ student.data.guardian.mobile }}
                </v-list-item-subtitle>
              </template>
              <template v-else>
                <v-list-item-title class="text-xs-right primary--text"
                  >Manage Transport
                </v-list-item-title>
              </template>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card outlined>
          <v-list-item @click="$router.push({ name: 'std-transport' })">
            <v-list-item-action>
              <v-icon color="#999">location_on</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text-xs-right">
                {{
                  student.data.address
                    ? student.data.address.permanent.address
                    : "-"
                }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-xs-right">
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card outlined>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="yellow darken-2">star</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text-xs-right">
                4.5 ( 10 reviews )
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card outlined>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="#999 darken-2">message</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="text-xs-right primary--text">
                SMS Guardian
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card outlined style="cursor: pointer">
          <v-list-item>
            <v-list-item-action>
              <v-icon color="#999">directions_bus</v-icon>
            </v-list-item-action>
            <v-list-item-content
              @click="$auth.can('student-update') ? updateOrEdit() : ''"
            >
              <template v-if="student.data.transport">
                <v-list-item-title class="text-xs-right">
                  {{ student.data.transport.destination }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-xs-right">
                  {{ student.data.transport.route }} /
                  <strong
                    >NRS
                    {{
                      Math.round(
                        student.data.transport.rate *
                          (student.data.transport.discount / 100)
                      )
                    }}
                  </strong>
                </v-list-item-subtitle>
              </template>
              <template v-else>
                <v-list-item-title class="text-xs-right primary--text"
                  >Manage Transport
                </v-list-item-title>
              </template>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-card>
    </v-flex>

    <v-flex xs8>
      <v-card class="primary--border" outlined>
        <table
          class="primary--border v-sheet theme--light"
          style="border: 1px solid black; width: 100%"
        >
          <thead>
            <tr>
              <th class="text-xs-left tbs "><strong>Batch</strong></th>
              <th class="text-xs-left tbs"><strong>Grade</strong></th>
              <th class="text-xs-left tbs"><strong>Section</strong></th>
              <th class="text-xs-left tbs"><strong>Roll</strong></th>
              <th class="text-xs-right tbs"><strong>Action</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(enroll, i) in student.data.batch_records" :key="i">
              <td class="tbs">{{ enroll.academic.batch }}</td>
              <td class="tbs">{{ enroll.academic.grade }}</td>
              <td class="tbs">{{ enroll.academic.section }}</td>
              <td class="tbs">{{ enroll.roll }}</td>
              <td class="text-xs-right">
                <!--                            <span v-if="eacademy.canAdmitStudent()">-->
                <!--                            <v-tooltip left color="warning" v-if="!i">-->
                <!--                            <v-btn flat icon small color="warning"-->
                <!--                            @click.native="$emit('edit-enroll', enroll)" slot="activator">-->
                <!--                            <v-icon>edit</v-icon>-->
                <!--                            </v-btn>-->
                <!--                            </v-btn>-->
                <!--                            <span>Edit Roll No.</span>-->
                <!--                            </v-tooltip>-->
                <!--                            <v-tooltip left color="error">-->
                <!--                            <v-btn flat icon small color="error"-->
                <!--                            @click.native="$emit('remove-enroll', enroll)" slot="activator">-->
                <!--                            <v-icon>delete</v-icon>-->
                <!--                            </v-btn>-->
                <!--                            <span>Delete Enroll</span>-->
                <!--                            </v-tooltip>-->
                <!--                            </span>-->
                <!--                            <span v-else>-->
                <!--                             - -->
                <!--                            </span>-->
              </td>
            </tr>
          </tbody>
        </table>
      </v-card>
    </v-flex>

    <v-dialog v-model="transportForm.dialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Add/Update</span>
        </v-card-title>

        <v-card-text class="pb-1">
          <v-form
            @submit.prevent="save"
            ref="transportForm"
            @keydown.native="transportForm.errors.clear($event.target.name)"
            @keyup.enter="save"
            lazy-validation
          >
            <br />
            <v-layout row wrap>
              <!--<v-flex xs12 sm12>-->
              <!--</v-flex>-->

              <v-flex xs12>
                <v-select
                  outlined
                  dense
                  :items="routes"
                  label="Route*"
                  required
                  class="pa-0 pl-2"
                  v-model="transportForm.route_id"
                  name="route_name"
                  :error-messages="transportForm.errors.get('route_id')"
                />
              </v-flex>
              <v-flex xs12>
                <v-select
                  outlined
                  dense
                  :disabled="destinations.length < 1"
                  :items="destinations"
                  label="Destination*"
                  required
                  class="pa-0 pl-2 pt-2"
                  v-model="transportForm.fare_id"
                  name="route_name"
                  :error-messages="transportForm.errors.get('fare_id')"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  outlined
                  dense
                  autocomplete="off"
                  type="number"
                  label="Fare Discount (in %)"
                  required
                  class="pa-0 pl-2 pt-2"
                  v-mask="'###'"
                  v-model="transportForm.discount"
                  name="fare_amount"
                  :error-messages="transportForm.errors.get('discount')"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            outlined
            @click="(transportForm.dialog = false), transportForm.reset()"
            >Close
          </v-btn>
          <v-btn color="success" outlined @click="saveTransportInfo"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
  <v-layout v-else-if="student.updateGuardian === true">
    <v-flex xs12>
      <v-card class="mb-2 elevation-1 pa-3 update-form-profile">
        <updateguardian
          :student="student"
          :is-profile-page="true"
        ></updateguardian>
      </v-card>
    </v-flex>
  </v-layout>
  <v-layout v-else-if="student.deleteAlert === true">
    <v-flex xs12>
      <v-card class="mb-2 elevation-1 pa-3 update-form-profile">
        <v-card-text class="pb-1" style="padding: 0;">
          <h1>Are you sure?</h1>
          <p>
            Please make sure that you want to delete
            <strong>{{ this.student.data.enroll_code }}</strong> -
            <strong>{{ this.student.data.name }}'s</strong>
            records.
          </p>

          <br />
          <strong>
            Input the student's enroll code if you want to continue.
          </strong>
          <v-flex>
            <v-flex xs11>
              <v-flex xs12>
                <v-container style="padding:0" grid-list-md>
                  <v-layout row wrap>
                    <v-flex xs5>
                      <v-text-field
                        outlined
                        dense
                        autocomplete="off"
                        label="Enroll Code.*"
                        required
                        class="pa-0"
                        v-model="delete_enroll"
                        name="rank"
                        :error-messages="form.errors.get('delete_enroll')"
                      />
                    </v-flex>
                    <v-flex xs5>
                      <v-text-field
                        outlined
                        dense
                        autocomplete="off"
                        label="Delete Reason.*"
                        required
                        class="pa-0 pl-3"
                        v-model="delete_reason"
                        name="rank"
                        :error-messages="form.errors.get('delete_reason')"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
            </v-flex>
          </v-flex>

          <v-card-actions style="padding-left: 7px; padding-bottom: 20px;">
            <v-btn color="success" outlined @click="SET_DELETE_ALERT(false)">
              Cancel
            </v-btn>

            <v-btn
              :disabled="
                student.data.enroll_code !== delete_enroll ||
                  delete_reason.length < 4
              "
              color="red"
              outline
              @click="deleteStudent"
              >Confirm Delete
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>

  <v-layout v-else>
    <v-flex xs12>
      <v-card class="mb-2 elevation-1 pa-3 update-form-profile">
        <v-card-text class="pb-1" style="padding: 0;">
          <v-form
            v-model="staff.validation.valid"
            ref="staffInfoForm"
            lazy-validation
          >
            <v-timeline class="user_add_form" align-top dense>
              <v-timeline-item color="red" small>
                <v-layout pt-3>
                  <v-flex>
                    <strong>Personal Information</strong>
                    <div class="caption">
                      Student's name, gender date of birth etc.
                    </div>
                    <v-flex xs11>
                      <v-flex pt-3 xs12>
                        <v-container style="padding:0" grid-list-md>
                          <v-layout row wrap>
                            <v-flex sm4 xs12>
                              <v-text-field
                                outlined
                                dense
                                autocomplete="off"
                                label="First Name*"
                                required
                                class="pa-0"
                                v-model="student.updateData.fname"
                                name="name"
                                :rules="staff.validation.fnameRule"
                                :error-messages="form.errors.get('fname')"
                              />
                            </v-flex>
                            <v-flex sm4 xs12>
                              <v-text-field
                                outlined
                                dense
                                autocomplete="off"
                                label="Middle Name*"
                                required
                                class="pa-0"
                                v-model="student.updateData.mname"
                                name="name"
                                :rules="staff.validation.fnameRule"
                                :error-messages="form.errors.get('mname')"
                              />
                            </v-flex>

                            <v-flex sm4 xs12>
                              <v-text-field
                                outlined
                                dense
                                autocomplete="off"
                                label="Last Name*"
                                required
                                class="pa-0"
                                v-model="student.updateData.lname"
                                name="name"
                                :rules="staff.validation.fnameRule"
                                :error-messages="form.errors.get('lname')"
                              />
                            </v-flex>

                            <v-flex sm3 xs12>
                              <v-autocomplete
                                outlined
                                dense
                                v-model="student.updateData.gender"
                                :error-messages="form.errors.get('gender')"
                                prepend-inner-icon="face"
                                :items="gender"
                                :rules="staff.validation.genderRule"
                                label="Gender"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>
                            <v-flex sm2 xs12>
                              <v-autocomplete
                                outlined
                                dense
                                v-model="student.updateData.blood_group"
                                :error-messages="form.errors.get('blood_group')"
                                prepend-inner-icon="add_location"
                                :items="blood"
                                label="Blood"
                                data-vv-name="select"
                                required
                              />
                            </v-flex>

                            <v-flex sm3 xs12>
                              <v-calendar-field
                                id-val="dob"
                                v-model="student.updateData.dob"
                                label="Date of Birth"
                              >
                              </v-calendar-field>
                            </v-flex>

                            <v-flex sm4 xs12>
                              <!--<v-text-field-->
                              <!--solo-->
                              <!--v-model="form.dob"-->
                              <!--:error-messages="form.errors.get('dob')"-->
                              <!--label="Date of birth"-->
                              <!--:rules="staff.validation.dobRule"-->

                              <!--prepend-inner-icon="cake"-->
                              <!--v-mask="'####-##-##'"></v-text-field>-->
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-flex>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-timeline-item>
              <v-timeline-item color="orange" small>
                <v-layout pt-3>
                  <v-flex>
                    <strong>Contact information</strong>
                    <div class="caption">Email/Phone</div>
                    <v-flex pt-3 xs11>
                      <v-container style="padding:0" grid-list-md>
                        <v-layout row wrap>
                          <v-flex xs12 sm5>
                            <v-text-field
                              outlined
                              dense
                              prepend-inner-icon="email"
                              autocomplete="off"
                              label="Email"
                              required
                              class="pa-0"
                              :rules="staff.validation.emailRule"
                              v-model="student.updateData.email"
                              name="rank"
                              :error-messages="form.errors.get('email')"
                            />
                          </v-flex>
                          <v-flex xs12 sm4>
                            <v-text-field
                              mask="##########"
                              outlined
                              dense
                              v-model="student.updateData.residence"
                              :error-messages="form.errors.get('residence')"
                              label="Phone"
                              prepend-inner-icon="perm_phone_msg"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-timeline-item>

              <v-timeline-item color="green" small>
                <v-layout pt-3>
                  <v-flex>
                    <strong>Other information</strong>
                    <div class="caption">Religion/Ethnicity</div>
                    <v-flex pt-3 xs11>
                      <v-container style="padding:0" grid-list-md>
                        <v-layout row wrap>
                          <v-flex sm5 xs12>
                            <v-autocomplete
                              outlined
                              dense
                              v-model="student.updateData.religion"
                              :error-messages="form.errors.get('religion')"
                              prepend-inner-icon="account_balance"
                              :items="religions"
                              label="Religion"
                              data-vv-name="select"
                              required
                            />
                          </v-flex>
                          <v-flex sm5 xs12>
                            <v-autocomplete
                              outlined
                              dense
                              prepend-inner-icon="place"
                              v-model="student.updateData.ethnicity"
                              :error-messages="form.errors.get('ethnicity')"
                              :items="ethnicities"
                              label="Ethnicity"
                              data-vv-name="select"
                              required
                            />
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-timeline-item>
            </v-timeline>
            <v-card-actions style="padding-left: 80px; padding-bottom: 80px;">
              <v-btn
                color="warning"
                outlined
                @click="SET_GENERAL_UPDATE(false)"
              >
                <v-icon dark left>arrow_back</v-icon>
                Cancel
              </v-btn>

              <v-btn color="success" outlined @click="updateGeneral"
                >Update General Info</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import Form from "@/library/Form";
import { mapState, mapActions, mapMutations } from "vuex";
import updateguardian from "./updateguardian";
import { validateDate } from "../../../../../library/helpers";

export default {
  components: {
    updateguardian,
  },
  props: {},
  data: () => ({
    delete_enroll: "",
    delete_reason: "",
    searchGuardian: "",
    guardianInfo: {},
    form: new Form({
      fname: "",
      mname: "",
      lname: "",
      gender: "",
      email: "",
      dob: "",
      blood_group: "",
      religion: "",
      ethnicity: "",
      residence: "",
    }),
    guardianForm: new Form({
      guardian_id: "",
      relation: "",
      family: {
        father: "",
        mother: "",
        grand_father: "",
      },
    }),
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
    guardianValidation: {
      validation: {
        valid: false,
        guardianRule: [(v) => !!v || "Please select a guardian"],
        relationRule: [(v) => !!v || "Please specify a relation"],
      },
    },
    transportForm: new Form(
      {
        route_id: "",
        enroll_id: "",
        discount: "",
        fare_id: "",
      },
      "/api/transport"
    ),
    transportValidation: {
      valid: false,
      route_id: [(v) => !!v || "Route is required"],
      discount: [
        (v) => v <= 100 || "Discount percentage cannot be greater than 100",
      ],
      fare_id: [(v) => !!v || "Fare is required"],
    },
    routes: [],
    destinations: [],
    update: false,
    enrolls: [],
    transport: {},
    gender: ["Male", "Female", "Others"],
    relations: [
      "Grandfather",
      "Grandmother",
      "Father",
      "Mother",
      "Brother",
      "Sister",
      "Uncle",
      "Aunt",
      "Other",
    ],
    ethnicities: [
      "Brahmin",
      "Chhetri",
      "Janajati",
      "Dalit",
      "Muslim",
      "Newari",
      "Madhesi",
      "Other",
    ],
    religions: ["Hindu", "Muslim", "Buddhist", "Christian", "Other"],
    blood: ["AB+", "AB-", "A+", "A-", "B+", "B-", "O+", "O-"],
    staff: {
      addressInfo: {
        validation: {},
      },
      validation: {
        valid: true,
      },
      selectedRole: "",
    },
    guardian: {},
    roles: [],
    guardianList: [],
  }),
  watch: {
    "transportForm.route_id": function() {
      this.getDestinations();
    },
    "student.updateData.fname": {
      handler(name) {
        this.student.updateData.fname = this.capitalizeString(name);
      },
    },
    "student.updateData.mname": {
      handler(name) {
        this.student.updateData.mname = this.capitalizeString(name);
      },
    },
    "student.updateData.lname": {
      handler(name) {
        this.student.updateData.lname = this.capitalizeString(name);
      },
    },
    searchGuardian: function(val) {
      if (!val) return;
      if (this.isLoading) return;
      this.isLoading = true;
      this.$rest
        .get("api/user-search/slim?type=guardian&search=" + val)
        .then(({ data }) => {
          this.guardianList = data.data;
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
    "guardianForm.family.grand_father": {
      handler(name) {
        this.guardianForm.family.grand_father = this.capitalizeString(name);
      },
    },
    "guardianForm.family.father": {
      handler(name) {
        this.guardianForm.family.father = this.capitalizeString(name);
      },
    },
    "guardianForm.family.mother": {
      handler(name) {
        this.guardianForm.family.mother = this.capitalizeString(name);
      },
    },
  },
  mounted() {
    if (this.student.data.guardian) {
      this.guardianList.push({
        id: this.student.data.guardian.id,
        Name: this.student.data.guardian.name,
      });
      this.guardianInfo = this.student.data.guardian;
    }
  },
  computed: {
    ...mapState(["student"]),
    guardianItems() {
      return this.guardianList.map((entry) => {
        return Object.assign({}, entry, { Name: entry.name });
      });
    },
    guardianSelected() {
      let guardian = {};
      if (this.guardianItems.length) {
        let $this = this;
        this.guardianItems.map(function(data) {
          if ($this.student.selectedGuardian.id === data.id) {
            guardian = data;
          }
        });
      }
      return guardian;
    },
  },
  methods: {
    ...mapActions(["setStudent", "getStudentInfo"]),
    ...mapMutations([
      "SET_GENERAL_UPDATE",
      "SET_GUARDIAN_UPDATE",
      "SET_DELETE_ALERT",
    ]),
    saveTransportInfo() {
      // if (this.$refs.transportForm.validate()) {
      this.transportForm.enroll_id = this.student.data.enroll_id;
      this.transportForm.store();
      // }
    },
    updateAction(state) {
      this.update = state;
    },
    store() {},
    updateGuardianInfo() {
      if (this.$refs.guardianInfoForm.validate()) {
        this.guardianForm.guardian_id = this.student.selectedGuardian.id;
        this.guardianForm.relation = this.student.selectedGuardian.relation;
        this.guardianForm.family = this.student.selectedGuardian.family;
        this.$rest
          .put(
            "api/student/" + this.$route.params.id + "/guardian",
            this.guardianForm.data()
          )
          .then((res) => {
            this.$events.fire("notification", { message: res.data.message });
            this.SET_GUARDIAN_UPDATE(false);
            //fetch student
            this.getStudentInfo("/api/student/" + this.$route.params.id).then(
              (res) => {
                // this.fetch_student_record = true;
              }
            );
          })
          .catch((err) => {
            this.$events.fire("notification", {
              message: err.response.data.message,
              status: "error",
            });
            // console.log(err.response.data);
            this.guardianForm.failure(err.response.data);
          });
      }
    },
    updateGeneral() {
      let $this = this;
      Object.keys(this.student.updateData).map(function(val) {
        $this.form[val] = $this.student.updateData[val];
      });
      this.form.dob = this.form.dob.dateForm();
      this.$rest
        .put(
          "api/student/" + this.$route.params.id + "/general",
          this.form.data()
        )
        .then((res) => {
          this.$events.fire("notification", { message: res.data.message });
          this.SET_GENERAL_UPDATE(false);
          this.form.endpoint = "/api/student/" + this.$route.params.id;
          this.form.get().then(({ data }) => {
            this.setStudent(data);
          });
        })
        .catch((err) => {
          this.form.failure(err.response.data);
        });
    },
    deleteStudent() {
      this.$rest
        .delete(
          "/api/student/" +
            this.student.data.id +
            "/enroll/" +
            this.student.data.enroll_id +
            "?reason=" +
            this.delete_reason
        )
        .then((res) => {
          this.$events.fire("notification", {
            message: "Student removed",
            status: "success",
          });
          this.$router.push({ name: "search-students" });
        });
    },

    getRoutes() {
      this.$rest.get("/api/route?rowsPerPage=25").then(({ data }) => {
        let $this = this;
        this.routes = data.data.map((route) => {
          return { text: route.name, value: route.id };
        });
      });
    },
    getDestinations() {
      this.$rest
        .get("/api/route-fare?routeId=" + this.transportForm.route_id)
        .then((res) => {
          this.destinations = res.data.map(function(des) {
            return { text: des.destination, value: des.fare_id };
          });
        });
    },
    updateOrEdit() {
      this.transportForm.dialog = true;
      this.getRoutes();

      if (this.student.data.transport) {
        this.transportForm.edit(this.student.data.transport);
      } else {
      }
    },
    updateValue(value) {},
    capitalizeString(string) {
      if (string === undefined || string === null) return "";
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="scss">
.profile-contents {
  width: 100%;
  margin: 26px auto;
}

.delete-alert-box {
  /*border-top: 5px solid red!important;*/
  box-shadow: 0 -5px 0 #df3030 !important;
  margin-top: 10px;
  padding: 50px 60px !important;

  .v-text-field {
    margin-left: -10px !important;
  }

  h1 {
    font-size: 30px;
    color: #666;
  }

  p {
    color: #666;
  }

  strong {
    color: #555;
  }
  table {
    border-collapse: collapse;
  }
  .tbs {
    border: 1px solid #cccccc;
    text-align: center;
  }
}
</style>
